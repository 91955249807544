import React, { useEffect, useRef, useState } from "react";
import "./index.css";

import moment from "moment";
import { FaAngleDoubleDown, FaHeart } from "react-icons/fa";
import Snowfall from "react-snowfall";

const App = () => {
  const theDay = moment("2023-05-28T00:20:00+01:00");

  const [timeSpent, setTimeSpent] = useState<{
    years: number;
    months: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }>({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [isQuoteHovered, setIsQuoteHovered] = useState(false);

  useEffect(() => {
    setInterval(() => {
      const now = moment();
      const duration = moment.duration(now.diff(theDay));

      setTimeSpent({
        years: duration.years(),
        months: duration.months(),
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    });
  }, []);

  const snowFlakes = [
    "https://media.valorant-api.com/sprays/c746b2d3-491b-62f1-a40a-a4b77aa57f05/fulltransparenticon.png",
    "https://media.valorant-api.com/agents/569fdd95-4d10-43ab-ca70-79becc718b46/abilities/ability2/displayicon.png",
    "https://media.valorant-api.com/agents/569fdd95-4d10-43ab-ca70-79becc718b46/abilities/ultimate/displayicon.png",
    "https://media.valorant-api.com/agents/6f2a04ca-43e0-be17-7f36-b3908627744d/abilities/grenade/displayicon.png",
  ];

  const letterRef = useRef<HTMLDivElement>(null);

  return (
    <main
      className="bg-neutral-900 text-white flex flex-col gap-32"
      style={{
        textShadow: "0 0 10px rgba(0,0,0,0.5)",
      }}
    >
      <section className="w-full h-screen flex items-center justify-center overflow-hidden relative">
        {snowFlakes.map((image) => {
          const img = document.createElement("img");
          img.src = image;
          return (
            <Snowfall
              key={image}
              style={{
                zIndex: 10,
              }}
              snowflakeCount={3}
              images={[img]}
              radius={[15, 75]}
            />
          );
        })}
        <p className=" text-6xl xl:text-9xl text-center relative z-10 hover:scale-110 duration-700 cursor-default">
          NEED HEALING
        </p>

        <FaHeart
          className="absolute text-[24rem] xl:text-[48rem] text-red-500"
          style={{
            animation: "heart 2.5s infinite",
          }}
        />
        <FaHeart
          className="absolute text-[24rem] xl:text-[48rem] text-red-500"
          style={{
            animation: "heartPulse 2.5s infinite",
          }}
        />

        <div className="absolute bottom-0">
          <div
            className="relative z-10 flex flex-col xl:flex-row xl:gap-2 items-center cursor-default text-center xl:text-2xl"
            title="Since 28/05/2023 at 00:20"
          >
            <p className="font-bold">Time spent together:</p>
            <div className="flex flex-wrap justify-center items-center gap-2">
              <p>
                {timeSpent.years} year
                {timeSpent.years !== 1 && "s"},
              </p>
              <p>
                {timeSpent.months} month
                {timeSpent.months !== 1 && "s"},
              </p>
              <p>
                {timeSpent.days} day
                {timeSpent.days !== 1 && "s"},
              </p>
              <p>
                {timeSpent.hours} hour
                {timeSpent.hours !== 1 && "s"},
              </p>
              <p>
                {timeSpent.minutes} minute
                {timeSpent.minutes !== 1 && "s"},
              </p>
              <p>
                {timeSpent.seconds} second
                {timeSpent.seconds !== 1 && "s"}
              </p>
              <FaHeart />
            </div>
          </div>
        </div>

        <div
          className="hidden xl:flex absolute bottom-0 right-1 opacity-50 gap-px items-center cursor-pointer"
          onClick={() =>
            letterRef.current!.scrollIntoView({
              behavior: "smooth",
            })
          }
        >
          Scroll down <FaAngleDoubleDown />
        </div>
      </section>

      {/* #85e2d1 */}
      <section
        className="w-full p-4 cursor-default flex flex-col gap-8 justify-center items-center"
        ref={letterRef}
      >
        <div className="text-4xl xl:text-7xl max-w-screen-2xl w-full">
          <p>It all started with an</p>
          <div>
            <p
              onMouseOver={() => setIsQuoteHovered(true)}
              onMouseOut={() => setIsQuoteHovered(false)}
              className="font-bold duration-300 inline"
              style={
                isQuoteHovered
                  ? {
                      textShadow: "3px 2px 0 #85e2d1",
                    }
                  : {
                      textShadow: "0px 0px 0 #85e2d1",
                    }
              }
            >
              "OMG I LOVE YOUR SITE 😊"
            </p>
            ,
          </div>
          <p>so this seems like a fitting way to do this.</p>
        </div>
        <div className="text-lg xl:text-2xl flex flex-col gap-8 max-w-screen-2xl w-full">
          <p>
            I am not a man who is good with words, but I will try my best
            anyway.
          </p>
          <p>
            I will get straight to the point.{" "}
            <u>
              <b>You are the best thing that ever happened to me</b>
            </u>
            . You are my sunshine, my reason to keep going, my closest friend,
            the one I trust the most, and the one that keeps me going. You are
            the reason I am happy.
          </p>
          <p>
            You have shown me a world I never knew existed, a world where I
            truly feel appreciated and cared about. You have changed me in a way
            I thought would never be possible. You have made me a better, more
            caring, and more joyful person. You have shown me how to love and be
            loved. You are the kind of person I have always dreamed about since
            I was young but never thought would actually exist in my life, and I
            am forever grateful to have met you.
          </p>
          <p>
            I would like to believe that our meeting was fate, given how
            unlikely it was for it to happen. We had to play the same game at
            the exact same time, and then somehow, despite our rank difference,
            we still ended up in the same lobby and on the same team. It’s such
            an unlikely scenario that it’s almost impossible for it to happen,
            but yet here we are. I often think about that Swiftplay game on
            Fracture and how such a wonderful thing could come out of a map I
            hated so much at the time. I remember jumping around spamming{" "}
            <span className="italic">“need healing!”</span> constantly like I
            had done in all my previous games, but this time I saw something I
            had not seen before, someone who joined in on my silliness. I
            already knew after that one game that you were someone I connected
            with in a special way, but I had no idea what this silly interaction
            would eventually lead to.
          </p>
          <p>
            I remember you adding me as a friend after the game and me sitting
            for a moment considering if I should accept. At that point, I was
            seriously invested in ranking up and playing competitively and
            wasn’t sure if I wanted to spend time playing non-competitive
            gamemodes, but I decided to follow my heart and decided to embrace
            having fun in the game and playing together with you. I sure am
            happy about that decision!
          </p>
          <p>
            You were so shy and anxious when we first met. I didn’t even get to
            hear your voice until around 6 months after we first met! Despite
            this, I tried my best to show that I still cared about you by
            spending a lot of time playing together with you and doing private
            1-on-1 coaching with you to get better at the game. I didn’t mind
            spending time helping you since I cared about you as a person and
            wanted to make you happy and better. My coaching might not have been
            the best and most precise, but you say it helped you get slightly
            better at the game, which is something I take a lot of pride in.
          </p>
          <p>
            I knew you were someone special when I decided to take a break from
            playing for a few months, and you kept asking me to come back and
            play with you. In all my time playing competitive games like
            VALORANT and taking lots of breaks to focus on other things in life,
            I have never had someone this persistent in wanting me to come back
            and play with them. It was already at this point that I started to
            like you more than a friend, but I wasn’t sure if I was overreacting
            and wasn’t sure you felt the same way. I remember when I came back
            to play and you were so incredibly excited, I almost felt bad for
            leaving you. In reality, I didn’t even want to come back to the
            game, but I missed playing with you so much that I decided to ignore
            my hesitation and return anyway.
          </p>
          <p>
            I often go back and read the messages from when you decided to ask
            the all-important question. I had liked you for quite a while at
            that point, but I was always nervous that you didn’t feel the same
            way. I remember the exact moment I read the message. My heart
            started beating so fast, and I almost started sweating. I couldn’t
            believe what I was reading, and I thought you were joking at first.
            I felt a way that I had never felt before, and I couldn’t even
            understand what was going on. I was extremely nervous and
            overwhelmed, which can be seen in the messages I sent you after, but
            in reality, I was so incredibly happy and careful with my language
            because I seriously loved you and wanted to do it right.
          </p>
          <p>
            After many long months of being together online, we finally met in
            real life at the end of the year. Words cannot describe how excited
            I was and how happy I was when I finally got to see you. At that
            exact moment, I knew that we truly had something special and that I
            wanted to spend the rest of my life together with you. You showed me
            a world I had never seen before and made me feel happier than I ever
            had been, and I cannot wait for the next time I get to see you.
          </p>
          <p>
            We both have our problems; everyone does, but I always try my
            hardest to care for you and make you happy. I might sometimes seem
            busy and feel like I don’t have time for you, and I might not always
            be the best at giving and receiving love, but I am trying my
            absolute best, and I appreciate you accepting me for who I am. I
            know you have been through a lot, and that sometimes makes things a
            little tough, but I will do everything I can to make this special
            and last. I never want to leave you, you are always good and
            beautiful enough, and I would never, EVER, replace you. You have
            done more for me than I could ever dream of, and you are the most
            important thing in my life. Always remember our saying,{" "}
            <span className="font-bold underline italic">
              “Together, forever”
            </span>
            .
          </p>
          <p>
            You are my favorite person in the world, and I love everything about
            you. I love your cute voice, your beautiful smile, and your lovely
            laugh. I love your deep eyes, your long, majestic hair, and your
            extremely caring and sometimes silly personality and humor. I love
            us screaming <span className="italic">"need healing!"</span> and how
            we laugh at it every time.
          </p>
          <p>
            <span className="font-bold underline">I love YOU</span>.
          </p>

          <div className="text-3xl xl:text-6xl text-center flex flex-col gap-6 font-bold items-center">
            <p className="text-[#85e2d1]">
              Ik hou van je en ik zal altijd van je houden.
            </p>
            <p className="text-red-500">
              Jeg elsker dig, og jeg vil altid elske dig.
            </p>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-center">
        <p className="absolute text-2xl z-10 cursor-default">28/05/2023</p>
        <FaHeart
          className="text-[16rem] w-full text-center text-red-500"
          style={{
            animation: "heart 2.5s infinite",
          }}
        />
      </section>
      <section className="text-center mb-8 text-xl"> — Your Christian</section>
    </main>
  );
};

export { App };
